import jwtDecode from "jwt-decode";
import http from "./httpService";
import { config } from "../_helpers";

const apiEndpoint = config.apiUrl + "auth";
const apiEndpointLogin = config.apiUrl + "authlogin";
const apiUrlAgency = config.apiUrl + "ana_agency";
const apiEndpointPage = config.apiUrl + "AuthPage";
const accessTokenKey = "token";
const logoutData = "loggedOut";

// get client info (ip only)
const getClientInfo = async () => {
  const fetchWithTimeout = (url, timeout = 2000) => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error("Request timed out"));
      }, timeout);

      fetch(url)
        .then(response => {
          clearTimeout(timer);
          if (!response.ok) {
            reject(new Error("Network response was not ok"));
          }
          resolve(response);
        })
        .catch(err => {
          clearTimeout(timer);
          reject(err);
        });
    });
  };

  try {
    const resGeo = await Promise.race([
      fetchWithTimeout("https://api.ipify.org?format=json"),
      fetchWithTimeout("https://ipinfo.io/ip?format=json"),
      fetchWithTimeout("https://ifconfig.me/ip"),
      new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout")), 2000))
    ]);

    const info = await resGeo.json();
    return info.ip || info.IPv4 || info;
  } catch (error) {
    console.error("Error fetching geolocalization: ", error);
    return "123.456.789.000"; // IP fisso di default
  }
};

// refreshToken da Context (da testare)
export async function AuthRefreshToken(refreshToken) {
  try {
    const info = await getClientInfo();
    http.setClientInfo(info);
    const aToken = localStorage.getItem(accessTokenKey);
    let uri = apiEndpoint + "/refreshToken";
    let res = encodeURI(uri);
    //console.log(JSON.stringify(refreshToken));
    const { data: jwt } = await http.post(res, {
      AccessToken: aToken,
      RefreshToken: refreshToken,
    });
    let a = JSON.stringify(jwt);
    let bearer = JSON.parse(a).token;
    localStorage.removeItem(accessTokenKey);
    localStorage.setItem(accessTokenKey, jwt["token"]);
    http.setJwt("Bearer " + bearer);
    return JSON.stringify(jwt["refreshToken"]);
  } catch (error) {
    console.error("Error refreshing token: ", error);
  }
}

// login
export async function AuthLogin(username, password) {
  try {
    const info = await getClientInfo();
    http.setClientInfo(info);
    const { data: jwt } = await http.post(apiEndpoint + "/authenticate", {
      username,
      password,
    });
    let a = JSON.stringify(jwt);
    let bearer = JSON.parse(a).token;
    let bearerRefresh = JSON.parse(a).refreshToken;
    const c = jwtDecode(bearer);
    http.setJwt("Bearer " + bearer);
    localStorage.setItem(accessTokenKey, jwt["token"]);
    localStorage.removeItem(logoutData);
    return JSON.stringify(jwt["refreshToken"]);
  } catch (error) {
    console.error("Error on AuthLogin: ", error);
  }
}

// logout
export function AuthLogout(postToServer) {
  if (postToServer) http.post(apiEndpoint + "/logout");
  localStorage.removeItem(accessTokenKey);
  localStorage.setItem(logoutData, Date.now());
  return null;
}

// access token
export async function AuthCheckAccessToken() {
  if (localStorage.getItem(accessTokenKey)) {
    let jwt = JSON.stringify(localStorage.getItem(accessTokenKey));
    const tok = jwtDecode(jwt);
    if (tok.exp < Date.now() / 1000) {
      localStorage.removeItem(accessTokenKey);
      localStorage.setItem(logoutData, Date.now());
      return null;
    }

    let bearer = JSON.parse(jwt);
    const info = await getClientInfo();
    http.checkHeader("Bearer " + bearer, info);
    return localStorage.getItem(accessTokenKey);
  } else return null;
}

export function AuthGetCurrentUser() {
  try {
    var jwt = "";
    if (localStorage.getItem(accessTokenKey))
      jwt = localStorage.getItem(accessTokenKey);
    // JSON.parse(localStorage.getItem(accessTokenKey)).token;
    else return null;
    //check if expired
    const tok = jwtDecode(jwt);
    if (tok.exp < Date.now() / 1000) {
      localStorage.removeItem(accessTokenKey);
      localStorage.setItem(logoutData, Date.now());
      return null;
    }
    return tok;
  } catch (ex) {
    console.log("ops:", ex);
    return null;
  }
}
// agency id
export function getAgencyId() {
  let jwt = JSON.stringify(localStorage.getItem(accessTokenKey));
  const tok = jwtDecode(jwt);
  return Number(tok.actort);
}
// user role
export function getUserRole() {
  let jwt = JSON.stringify(localStorage.getItem(accessTokenKey));
  const tok = jwtDecode(jwt);
  return tok.role;
}

export function getUserId(rToken) {
  let jwt = JSON.stringify(rToken);
  const tok = jwtDecode(jwt);
  return Number(tok.userversion);
}

export function getUserName(rToken) {
  let jwt = JSON.stringify(rToken);
  const tok = jwtDecode(jwt);
  return tok.given_name + " " + tok.family_name;
}
// role
export function checkRole(policy) {
  let jwt = JSON.stringify(localStorage.getItem(accessTokenKey));
  const tok = jwtDecode(jwt);
  if (tok.role == policy || tok.role == "SuperUser") return true;
  else return false;
}

export function allowPrivateMessage() {
  let jwt = JSON.stringify(localStorage.getItem(accessTokenKey));
  const tok = jwtDecode(jwt);
  if (
    tok.role.toLowerCase() == "superuser" ||
    tok.role.toLowerCase() == "admin"
  )
    return true;
  else return false;
}

export async function AuthChangePassword(oldPassword, newPassword) {
  let uri = apiEndpointLogin + "/change-password";
  let res = encodeURI(uri);
  await http.post(res, {
    oldPwd: oldPassword,
    newPwd: newPassword,
  });
}

export async function checkGeoLocation(latitude, longitude, weblang) {
  let uri = apiUrlAgency + "/geolocation";
  let res = encodeURI(uri);
  return await http.post(res, {
    latitude: latitude,
    longitude: longitude,
    enabled: false,
    lastOperation: "",
    weblang: weblang,
  });
}

export async function AuthResetPassword(userIdToReset) {
  await http.post(apiEndpoint + "/reset-password/" + userIdToReset);
}

export async function AuthCreate(data) {
  const copyData = { ...data };
  return await http.post(apiEndpoint + "/create", copyData);
}

export async function AuthUpdate(data) {
  const copyData = { ...data };
  delete copyData.id;
  return await http.put(apiEndpoint + "/update/" + data.id, copyData);
}

export async function updateProfile(data) {
  const copyData = { ...data };
  delete copyData.id;
  return await http.put(apiEndpoint + "/updateprofile/" + data.id, copyData);
}

export function AuthDelete(id) {
  return http.delete(apiEndpoint + "/delete/" + id);
}

export function getAnaData() {
  try {
    return http.get(apiEndpoint + "/ana");
  } catch (error) {
    console.error("Error on getAnaData: ", error);
  }
}

export async function AuthEnable(id) {
  try {
    return await http.get(apiEndpoint + "/enable/" + id);
  } catch (error) {
    console.error("Error on AuthEnable: ", error);
  }
}

export async function AuthSendMail(id) {
  try {
    return await http.get(apiEndpoint + "/sendmail/" + id);
  } catch (error) {
    console.error("Error on AuthSendMail: ", error);
  }
}

export async function AuthRoute() {
  try {
    return await http.get(apiEndpointPage + "/routes");
  } catch (error) {
    console.error("Error on AuthRoute: ", error);
  }
}
export async function AuthMenu(lang) {
  try {
    return await http.get(apiEndpointPage + "/menu/" + lang);
  } catch (error) {
    console.error("Error on AuthMenu: ", error);
  }
}

// Send Reset Code
export async function AuthSendResetCode(email) {
  let uri = apiEndpoint + "/send-reset-code";
  let res = encodeURI(uri);
  await http.post(res, {
    email: email,
    dataCheck: "CICASS79 S.L.",
  });
}
// Reset Pwd with Code
export async function AuthResetPasswordByCode(email, code) {
  let uri = apiEndpoint + "/reset-password";
  let res = encodeURI(uri);
  await http.post(res, {
    email: email,
    dataCheck: code,
  });
}

export const auth = {
  AuthRefreshToken,
  AuthLogout,
  AuthLogin,
  AuthGetCurrentUser,
  AuthCheckAccessToken,
  AuthMenu,
  AuthRoute,
  AuthSendResetCode,
  AuthResetPasswordByCode,
  AuthResetPassword,
  AuthChangePassword,
  AuthCreate,
  AuthDelete,
  AuthUpdate,
  AuthEnable,
  AuthSendMail,
  allowPrivateMessage,
  getAnaData,
  getAgencyId,
  getUserId,
  getUserName,
  getUserRole,
  checkRole,
  checkGeoLocation,

  updateProfile,
};
