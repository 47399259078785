import React from "react";
import { Switch, Redirect } from "react-router-dom";
import TokenContext from "components/TokenContext";
import { PrivateRoute } from "../components/common";
import { cicassService, auth } from "../_services";
import Login from "views/auth/Login";
import CssBaseline from "@mui/material/CssBaseline";

import AdminToolbar from "components/Headers/adminToolbar";
import FooterAdmin from "components/Footers/FooterAdmin.js";

import Dashboard from "views/admin/Dashboard";
import {
  HomePage,
  SettingPage,
  AgencyPage,
  // CalendarPage,
  ShiftPage,
  UserPage,
  UserRolePage,
  AuthControlPage,
  AuthPage,
  RolePage,
  AvailabilityPage,
  FrequencyPage,
  AnaCalendarPage,
  AnaCalendarShiftPage,
  AnaCalendarShiftRolePage,
  AnaCalendarManagerPage,
  VacationPage,
  AttendancePage,
  BoardPage,
  AttendanceManagerPage,
  TicketStatusPage,
  TicketCategoryPage,
  TicketPage,
  ProfilePage,
  CalXlsPage,
  TemplatePage,
  AnaFieldPage,
  AnaCalendarFreePage,
  ShiftHistory,
  AnaAttendancePage,
  AnaTruckPage
} from "../views/pages";
import ScrollToTopButton from "components/common/ScrollToTopButton";

const apiUrlCalAgency = "ana_calendar/toolbar";

function Admin(props) {
  const useCounterContext = () => React.useContext(TokenContext);
  const [routesAuth, setRoutesAuth] = React.useState([]); //tutte le stations
  const [navigationAuth, setNavigationAuth] = React.useState([]);
  const [calendarData, setCalendarData] = React.useState([]);
  let { jwt } = useCounterContext();

  function returnComponent(comp) {
    switch (comp) {
      case "HomePage":
        return HomePage;
      case "SettingPage":
        return SettingPage;
      case "AgencyPage":
        return AgencyPage;
      case "BoardPage":
        return BoardPage;
      case "ShiftPage":
        return ShiftPage;
      case "UserPage":
        return UserPage;
      case "UserRolePage":
        return UserRolePage;
      case "AuthControlPage":
        return AuthControlPage;
      case "AuthPage":
        return AuthPage;
      case "RolePage":
        return RolePage;
      case "AvailabilityPage":
        return AvailabilityPage;
      case "FrequencyPage":
        return FrequencyPage;
      case "AnaCalendarPage":
        return AnaCalendarPage;
      case "AnaCalendarShiftPage":
        return AnaCalendarShiftPage;
      case "AnaCalendarShiftRolePage":
        return AnaCalendarShiftRolePage;
      case "AnaCalendarManagerPage":
        return AnaCalendarManagerPage;
      case "AttendancePage":
        return AttendancePage;
      case "AttendanceManagerPage":
        return AttendanceManagerPage;
      case "VacationPage":
        return VacationPage;
      case "TemplatePage":
        return TemplatePage;
      case "TicketCategoryPage":
        return TicketCategoryPage;
      case "TicketPage":
        return TicketPage;
      case "TicketStatusPage":
        return TicketStatusPage;
      case "ProfilePage":
        return ProfilePage;
      case "CalXlsPage":
        return CalXlsPage;
      case "AnaFieldPage":
        return AnaFieldPage;
      case "ShiftChangeHistoryPage":
        return ShiftHistory;
      case "AttendanceTypePage":
        return AnaAttendancePage;
      case "AnaTruckPage":
          return AnaTruckPage;
      default:
        return Dashboard;
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    if (jwt) {
      let lang = localStorage.getItem("weblang");
      await auth.AuthMenu(lang).then((response) => {
        setNavigationAuth(response.data);
      });
      await auth.AuthRoute(lang).then((response) => {
        setRoutesAuth(response.data);
      });
      await cicassService.getAll(apiUrlCalAgency).then((response) => {
        setCalendarData(response.data);
      });
    }
  }

  if (!jwt) {
    props.history.push("/auth/login");
  }
  return (
    <>
      {navigationAuth.length > 0 && (
        <React.Fragment>
          <CssBaseline />
          <AdminToolbar
            menu={navigationAuth}
            calendars={calendarData}
            history={props.history}
          />
          <div>
            <div>
              <Switch>
                {routesAuth &&
                  routesAuth.map((item, index) => {
                    return [
                      <PrivateRoute
                        path={"/app" + item.Path}
                        key={"/app" + item.Path + index}
                        component={returnComponent(item.Component)}
                      />,
                    ];
                  })}
                <PrivateRoute
                  exact
                  key="/app/home"
                  path="/app/home"
                  component={HomePage}
                />
                <PrivateRoute
                  exact
                  key={"/auth/login"}
                  path="/auth/login"
                  component={(props) => <Login {...props} />}
                />
                <Redirect to={"/app/home"} />
              </Switch>
            </div>
            <FooterAdmin />
            <ScrollToTopButton />
          </div>
        </React.Fragment>
      )}
    </>
  );
}
export default Admin;
